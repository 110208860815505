/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { useFormik } from 'formik'
import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import errorHelper from '../../../utils/error-helper'
import { getUserByToken, login } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required').trim(),
  password: Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Password is required').trim(),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      setLoading(true)
      login(values.email, values.password)
        .then(({ data: { token } }) => {
          setLoading(false)
          dispatch(auth.actions.login(token))

          return getUserByToken()
        })
        .then(({ data }) => {
          dispatch(auth.actions.setUser(data))
        })
        .catch((err) => {
          setLoading(false)
          errorHelper(err)
        })
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      {/*<div className='text-center mb-10'>*/}
      {/*  <h1 className='text-dark mb-3'>Sign In</h1>*/}
      {/*  <div className='text-gray-400 fw-bold fs-4'>*/}
      {/*    New Here?{' '}*/}
      {/*    <Link to='/auth/registration' className='link-primary fw-bolder'>*/}
      {/*      Create an Account*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/*<Link*/}
            {/*  to='/auth/forgot-password'*/}
            {/*  className='link-primary fs-6 fw-bolder'*/}
            {/*  style={{ marginLeft: '5px' }}*/}
            {/*>*/}
            {/*  Forgot Password ?*/}
            {/*</Link>*/}
          </div>
        </div>
        <input
          type='password'
          placeholder='Password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid || loading}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
