import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../_metronic/helpers';
import errorHelper from '../../utils/error-helper';
import successHelper from '../../utils/success-helper';
import {deleteCategoryType} from "../../apis/categoryTypeApis";

type Props = {
  onAfterHandle: () => void
}

interface TypeData {
  id: number
}

const ModalDeleteCategoryType = forwardRef(({ onAfterHandle }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<TypeData | null>(null)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    open(dataJob: any) {
      setVisible(true)
      setData(dataJob)
    }
  }))

  const onSubmit = (e: any) => {
    e.preventDefault()

    setLoading(true)
    deleteCategoryType(data?.id)
      .then(() => {
        successHelper('Delete HashTag Success!')
        onAfterHandle()
        onClose()
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onClose = () => {
    setVisible(false)
    setData(null)
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='mw-450px h-auto'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        onSubmit={onSubmit}
        className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            Delete Category Type
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        <div className='modal-footer d-flex justify-content-center'>
          <button type="reset" className='btn btn-light-primary' onClick={onClose}>
            Cancel
          </button>
          <button
            type='submit'
            className='btn btn-danger' disabled={loading}>
            {!loading && <span className='indicator-label'>Delete</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'/>
              </span>
            )}
          </button>
        </div>
      </form>
    </Modal>
  )
})

export { ModalDeleteCategoryType };

