import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from "yup";
import { KTSVG } from '../../../_metronic/helpers';
import errorHelper from '../../utils/error-helper';
import successHelper from '../../utils/success-helper';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import commonApis from '../../apis/commonApis';
import { UploadImg } from '../../components/UploadImg'
import { ARTICLE_TYPES } from '../../constants';
import { createArticleHealthCare, updateArticleHealthCare } from '../../apis/healthCareApis';
import { findHashtags, stringListHashtags } from '../../utils/funcs';
import { CategoryArticleSelect, IOptionSelect } from '../../components/CategoryArticleSelect';



type Props = {
  onAfterHandle: (data?: any) => void
}

interface FormData {
  title: string,
  timeRead: number | null,
  articleType: number | null,
  price: number | null,
  content: string,
  thumbnailUrl: string,
  listHashTag: any,
  imageUrl: string,
}

interface TypeData extends FormData {
  id: number | undefined
}

const schema = yup.object({
  title: yup.string().max(255, 'Maximum 255 symbols').required('Required').trim(),
  timeRead: yup.number().required('Required').nullable().transform((_, val) => val ? Number(val) : null),
  articleType: yup.number().required('Required').nullable().transform((_, val) => val ? Number(val) : null),
  price: yup.number().nullable().transform((_, val) => val ? Number(val) : null),
  thumbnailUrl: yup.string().required('Required'),
  content: yup.string().required('Required'),
  imageUrl: yup.string().required('Required'),
});

const ModalHandleArticleHealthCare = forwardRef(({ onAfterHandle }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<TypeData | null>(null)
  const [loading, setLoading] = useState(false)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [backGroundUrl, setBackGroundUrl] = useState('')
  const [listCategory, setListCategory] = useState<IOptionSelect[]>([])

  const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm<FormData>({
    resolver: yupResolver(schema),
  })
  const { articleType } = useWatch({ control })

  useImperativeHandle(ref, () => ({
    open(dataArticle: any) {
      setVisible(true)
      setData(dataArticle)
      if (dataArticle) {
        const listCategoryProps = dataArticle.articleCategory.length ? dataArticle.articleCategory.map((item: any) => { return item.category }) : []
        reset({
          title: dataArticle.title,
          timeRead: dataArticle.timeRead,
          articleType: dataArticle.type,
          price: dataArticle.price,
          thumbnailUrl: dataArticle.thumbnailUrl,
          content: dataArticle.content,
          imageUrl: dataArticle.imageUrl,
        })
        setThumbnailUrl(dataArticle.thumbnailUrl)
        setBackGroundUrl(dataArticle.imageUrl)
        setListCategory(listCategoryProps)
      }
    }
  }))

  const onSubmit = (values: any) => {
    const { articleType, price, timeRead, title, thumbnailUrl, content, listHashTag, imageUrl }: any = values
    const listCategoryId = listCategory?.length ? listCategory.map(g => { return g.id }) : [];
    setLoading(true)
    let payload = {}
    if (articleType == ARTICLE_TYPES.FREE) {
      const price = 0
      payload = {
        type: articleType,
        price,
        timeRead,
        title,
        thumbnailUrl,
        content,
        imageUrl,
        listCategoryId
      }
    } else {
      payload = {
        type: articleType,
        price,
        timeRead,
        title,
        thumbnailUrl,
        content,
        imageUrl,
        listCategoryId
      }
    }
    if (data) {
      updateArticleHealthCare(payload, data.id)
        .then(({ data }) => {
          successHelper('Update Article Success!')
          onAfterHandle(data)
          onClose()
        })
        .catch((err) => {
          errorHelper(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      createArticleHealthCare(payload)
        .then(({ data }) => {
          successHelper('Create Article Success!')
          onAfterHandle(data)
          onClose()
        })
        .catch((err) => {
          errorHelper(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onClose = () => {
    setVisible(false)
    setData(null)
    setListCategory([])
    reset({
      title: '',
      timeRead: null,
      articleType: null,
      price: null,
      thumbnailUrl: '',
      content: '',
      listHashTag: '',
      imageUrl: ''
    })
  }

  const uploadAdapter = (loader: any) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file: any) => {
            commonApis
              .preUploadFile({
                fileName: file.name,
                contentType: file.type,
              })
              .then(async ({ data: resPreUpload }) => {
                const { urlEndpoint, urlUpload } = resPreUpload
                await commonApis.uploadFile({
                  urlUpload,
                  file,
                })
                resolve({
                  default: urlEndpoint
                });
              })
              .catch((err) => {
                // errorHelper(err);
                reject(err);
              })
              .finally(() => {
              });
          });
        });
      }
    };
  }

  const handleThumbnailUrl = (url: string) => {
    setThumbnailUrl(url)
    setValue('thumbnailUrl', url, { shouldValidate: true, shouldDirty: true })
  }

  const handleImgUrl = (url: string) => {
    setBackGroundUrl(url)
    setValue('imageUrl', url, { shouldValidate: true, shouldDirty: true })
  }

  const renderArticleType = (type: number) => {
    switch (type) {
      case ARTICLE_TYPES.FREE:
        return "Free"
      case ARTICLE_TYPES.CHARGE_COST:
        return "Charge cost"
      default:
        return ''
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='modal-lg'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {data ? 'Update Article' : 'Create Article'}
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        <div className='modal-body'>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Title</label>
            <input
              placeholder='Title'
              {...register("title")}
              name='title'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.title },
              )}
              autoComplete='off'
            />
            {errors.title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.title.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Time Read</label>
            <input
              placeholder='Time read'
              {...register("timeRead")}
              name='timeRead'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.timeRead },
              )}
              autoComplete='off'
            />
            {errors.timeRead && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.timeRead.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Article Type</label>
            <select
              placeholder='Article type'
              {...register("articleType")}
              name='articleType'
              className={clsx(
                'form-select form-select-solid',
                { 'is-invalid': errors.articleType },
              )}
            >
              <option value={ARTICLE_TYPES.FREE}>{renderArticleType(ARTICLE_TYPES.FREE)}</option>
              <option value={ARTICLE_TYPES.CHARGE_COST}>{renderArticleType(ARTICLE_TYPES.CHARGE_COST)}</option>
            </select>
            {errors.articleType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.articleType.message}</span>
                </div>
              </div>
            )}
          </div>
          {
            articleType && articleType == ARTICLE_TYPES.CHARGE_COST && (
              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Price</label>
                <input
                  placeholder='Price'
                  {...register("price")}
                  name='price'
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    { 'is-invalid': errors.price },
                  )}
                  autoComplete='off'
                />
                {errors.price && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.price.message}</span>
                    </div>
                  </div>
                )}
              </div>
            )
          }
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>List Category </label>
            <CategoryArticleSelect
              acceptCreate={false}
              value={listCategory}
              onChangeValue={(newValue: IOptionSelect[]) => setListCategory(newValue)}
            />
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Thumbnail</label>
            <Controller
              name='thumbnailUrl'
              control={control}
              render={({ field }) => (
                <UploadImg
                  value={thumbnailUrl}
                  onChange={handleThumbnailUrl}
                />
              )}
            />

            {errors.thumbnailUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.thumbnailUrl.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>BackGround Image</label>
            <Controller
              name='imageUrl'
              control={control}
              render={({ field }) => (
                <UploadImg
                  value={backGroundUrl}
                  onChange={handleImgUrl}
                />
              )}
            />

            {errors.thumbnailUrl && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.thumbnailUrl.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Content</label>
            <Controller
              name="content"
              control={control}
              render={({ field }) => (
                <CKEditor
                  data={field.value}
                  editor={DecoupledEditor}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData();
                    setValue('content', data, { shouldValidate: true, shouldDirty: true })
                  }}
                  onReady={(editor: any) => {
                    editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
                      return uploadAdapter(loader);
                    };
                    editor.ui.getEditableElement().parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                  }}
                  config={{
                    removePlugins: ['MediaEmbed'],
                    image: {
                      insert: {
                        type: 'inline'
                      }
                    }
                  }}
                />
              )}
            />
            {errors.content && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.content.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='modal-footer d-flex justify-content-center'>
            <button type="reset" className='btn btn-light-primary' onClick={onClose}>
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary' disabled={loading}>
              {!loading && (data ? <span className='indicator-label'>Update</span> : <span className='indicator-label'>Create</span>)}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
})

export { ModalHandleArticleHealthCare };

