/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { formatDate } from "../../utils/funcs";
import { KTSVG } from "../../../_metronic/helpers";
import { CONTENT_TYPE, GROUP_REASON, STATUS } from "../../constants";
import { ModalApproveReport } from './ModalApproveReport'
import { ModalRejectReport } from './ModalRejectReport'
import { getListReport } from '../../apis/reportApis';
import { ModalDetailContent } from './ModalContentDetail';

let timeout: any

const Report: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const refModalApproveReport = useRef<any>(null)
  const refModalRejectReport = useRef<any>(null)
  const refModalDetailContent = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onAfterHandle = () => {
    onGetListReport()
  }

  const onAfterDelete = () => {
    if (page === 1) {
      onGetListReport()
    } else {
      setPage(1)
    }
  }

  const onGetListReport = useCallback(() => {
    const params = {
      search,
      page,
      size: limit,
      sorts: "updatedAt:DESC"
    }

    setFetching(true)

    getListReport(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data

        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit])

  useEffect(() => {
    onGetListReport()
  }, [onGetListReport])

  const renderStatus = (status: number) => {
    switch (status) {
      case STATUS.PENDING:
        return "PENDING"
      case STATUS.REJECT:
        return "REJECT"
      case STATUS.APPROVE:
        return "APPROVED"
    }
  }

  const renderTypeContent = (typeContent: number) => {
    switch (typeContent) {
      case CONTENT_TYPE.COMMENT:
        return "Comment"
      case CONTENT_TYPE.POST:
        return "Post"
      case CONTENT_TYPE.VIDEO:
        return "Video"
      case CONTENT_TYPE.PODCAST:
        return "Podcast"
      case CONTENT_TYPE.COMMENT_ARTICLE:
        return "Comment article"
      default:
        return " "
    }
  }

  const renderGroupReason = (groupReasonId: number) => {
    switch (groupReasonId) {
      case GROUP_REASON.SUICIDE:
        return "Content that promotes suicide or promotes suicide or self-harm"
      case GROUP_REASON.NAKED:
        return "Contains nudity or sexual activity"
      case GROUP_REASON.LANGUAGE:
        return "Hostile langue"
      case GROUP_REASON.TERRORISM:
        return "Terrorism"
      case GROUP_REASON.SPAM:
        return "Spam"
      case GROUP_REASON.FAKE_INFORMATION:
        return "Fake information"
    }
  }

  const renderUsername = (item: any) => {
    switch (item.contentType) {
      case CONTENT_TYPE.POST:
        return (
          <>
            <td className='ps-4'>
              {item?.artistPost?.artist?.user?.username}
            </td>
            <td>
              {renderTypeContent(item.contentType)}
            </td>
            <td className='box-content row' onClick={() => refModalDetailContent.current.open(item)}>
              <div className='box-content-text col-6'>
                {item?.artistPost?.content}
              </div>
              {
                item?.artistPost?.artistPostImage?.map((link: any, index: number) => (
                  <img
                    className='col-6'
                    src={link?.image}
                    key={index}
                  />
                ))
              }
            </td>
          </>
        )
      case CONTENT_TYPE.COMMENT:
        return (
          <>
            <td className='ps-4'>
              {item?.artistPostComment?.user?.username}
            </td>
            <td>
              {renderTypeContent(item.contentType)}
            </td>
            <td className='box-content row' onClick={() => refModalDetailContent.current.open(item)}>
              <div className='box-content-text col-6'>
                {item?.artistPostComment?.message}
              </div>
              {
                item?.artistPostComment?.artistPostCommentImage?.map((link: any, index: number) => (
                  <img
                    className='col-6'
                    src={link?.image}
                    key={index}
                  />
                ))
              }
            </td>
          </>
        )
      case CONTENT_TYPE.COMMENT_ARTICLE:
        return (
          <>
            <td className='ps-4'>
              {item?.articleComment?.user?.username}
            </td>
            <td>
              {renderTypeContent(item.contentType)}
            </td>
            <td className='box-content row' onClick={() => refModalDetailContent.current.open(item)}>
              <div className='box-content-text col-6'>
                {item?.articleComment?.message}
              </div>
              {
                item?.articleComment?.articleComment?.map((link: any, index: number) => (
                  <img
                    className='col-6'
                    src={link?.image}
                    key={index}
                  />
                ))
              }
            </td>
          </>
        )
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Report
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Username'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List Report</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'reports' : 'company'}`}</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>Username</th>
                    <th className='min-w-200px'>Type Content</th>
                    <th className='min-w-150px'>Content</th>
                    <th className='min-w-120px'>Reason</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='pe-4 min-w-100px text-end rounded-end'>Actions</th>
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={index}>

                          {
                            renderUsername(item)
                          }
                          <td className="ps-4">
                            {renderGroupReason(item.groupReasonId)}
                          </td>
                          <td>
                            {renderStatus(item.status)}
                          </td>
                          <td>
                            {item.status === -1 && (
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <button
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title="Reject Report"
                                  onClick={() => refModalRejectReport.current.open(item)}
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                >
                                  <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon-3' />
                                </button>
                                <button
                                  data-bs-toggle='tooltip'
                                  data-bs-placement='top'
                                  title="Approve Report"
                                  onClick={() => refModalApproveReport.current.open(item)}
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen037.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalApproveReport onAfterHandle={onAfterHandle} ref={refModalApproveReport} />
      <ModalRejectReport onAfterHandle={onAfterDelete} ref={refModalRejectReport} />
      <ModalDetailContent onAfterHandle={onAfterHandle} ref={refModalDetailContent} />
    </>
  )
}

export { Report }
