import axios from 'axios'

interface TypeResCategory{
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}

const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_CATEGORY_TYPE_URL = `/api/category-type`
export const GET_LIST_ALL_CATEGORY_TYPE_URL = `/api/category-type/all`
export const CREATE_CATEGORY_TYPE_URL = `/api/category-type`
export const UPDATE_CATEGORY_TYPE_URL = `/api/category-type`
export const DELETE_CATEGORY_TYPE_URL = `/api/category-type`


export function getListCategoryType(params: TypeParamsGetJob) {
  return axios.get<TypeResCategory>(GET_LIST_CATEGORY_TYPE_URL, { params })
}

export function getListAllCategoryType() {
  return axios.get(GET_LIST_ALL_CATEGORY_TYPE_URL)
}

export function createCategoryType(name: string) {
  return axios.post(CREATE_CATEGORY_TYPE_URL, name)
}

export function updateCategoryType(name: string, id: number | undefined) {
  return axios.put(`${UPDATE_CATEGORY_TYPE_URL}/${id}`, name)
}

export function deleteCategoryType(id: number | undefined) {
  return axios.delete(`${DELETE_CATEGORY_TYPE_URL}/${id}`)
}

