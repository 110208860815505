import classNames from 'classnames'
import React, { forwardRef, useRef } from 'react'
import Input from './Input'

interface IInputAction {
    loading?: boolean,
    className?: string,
    rightAction?: any,
    onChange?: any,
    value?: any,
    startDate?: string,
    tabIndex?: string
  }

const InputAction = ({ loading = false, className, rightAction, ...props }:IInputAction) => {
    const ref = useRef<any>()
  return (
    <div className="input-action d-flex pe-0 align-items-center input-group">
      <Input
        {...props}
        value={props.value}
        onChange={props.onChange}
        ref={ref}
        className={classNames(className, 'form-control form-control-lg')}
      />
      <div className="action-right input-group-text">
        {rightAction && (
          rightAction
        )}
      </div>
    </div>
  )
}

export default forwardRef(InputAction)