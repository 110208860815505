import axios from 'axios'

interface TypeResHealthCare {
  count: number
  rows: Array<{
    address: string
    name: string
    phone: string
  }>
}

interface TypeParamsGetUser {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_ARTICLE_HEALTHCARE_URL = `/api/article`
export const CREATE_ARTICLE_HEALTHCARE_URL = `/api/article`
export const UPDATE_ARTICLE_HEALTHCARE_URL = `/api/article`
export const DELETE_ARTICLE_HEALTHCARE_URL = `/api/article`
export const CHANGE_STATUS_ARTICLE_HEALTHCARE_URL = `/api/article`


export function getListArticleHealthCare(params: TypeParamsGetUser) {
  return axios.get<TypeResHealthCare>(GET_LIST_ARTICLE_HEALTHCARE_URL, { params })
}

export function createArticleHealthCare(payload: object) {
  return axios.post(CREATE_ARTICLE_HEALTHCARE_URL, payload
  )
}

export function updateArticleHealthCare(payload: object, id: number | undefined) {
  return axios.put(`${UPDATE_ARTICLE_HEALTHCARE_URL}/${id}`, payload)
}

export function deleteArticleHealthCare(id: number | undefined) {
  return axios.delete(`${DELETE_ARTICLE_HEALTHCARE_URL}/${id}`)
}

export function changeStatusArticleHealthCare(id: number | undefined) {
  return axios.put(`${CHANGE_STATUS_ARTICLE_HEALTHCARE_URL}/${id}/change-status`)
}

