import React, { useEffect, useState } from 'react';
import DatePickerCustom from './DatePickerCustom';

export interface IRangePicker {
  startDate?: any,
  endDate?: any,
  onChange: (value: any) => void,
  onChangeRaw: (value: any) => void,
  onKeyDown: (value: any) => void,
  maxTime?: any,
  dayClassName: (value: any) => void,
  inputFormat?: string
  selectsRange?: any
}

const RangePicker = ({ startDate, endDate, onChange, ...props }:IRangePicker) => {
  const [currentDate, setCurrentDate] = useState([null, null])

  useEffect(() => {
    if (startDate && endDate) {
      setCurrentDate([startDate, endDate])
    }
  }, [startDate, endDate])

  return (
    <DatePickerCustom
      {...props}
      dateFormat={'yyyy-MM-dd'}
      startDate={currentDate[0]}
      endDate={currentDate[1]}
      selectsRange
      onChange={(newDate:any) => {
        const [start, end] = newDate
        setCurrentDate([start, end])

        if ((start && end) || (!start && !end)) {
          onChange([start, end])
        }
      }}
    />
  );
};

export default RangePicker;