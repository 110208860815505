/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { formatDate, stringListCategoryArticle, stringListHashtags } from "../../utils/funcs";
import { KTSVG } from "../../../_metronic/helpers";
import { ARTICLE_TYPES, CONTENT_TYPE, GROUP_REASON, STATUS, STATUS_CHECK } from "../../constants";
import { changeStatusArticleHealthCare, deleteArticleHealthCare, getListArticleHealthCare } from '../../apis/healthCareApis';
import { Button } from 'react-bootstrap-v5';
import { ModalHandleArticleHealthCare } from './ModalHandleArticleHealthCare';
import { ModalChangeStatus } from '../../components/ModalChangeStatus';
import { ModalDetailArticleHealCare } from './ModalDetailArticleHealthCare';
import { ModalDelete } from '../../components/ModalDelete';

let timeout: any

const HealthCare: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const refModalHandleArticle = useRef<any>(null)
  const refModalChangeStatus = useRef<any>(null)
  const refModalDeleteArticle = useRef<any>(null)
  const refModalDetailArticle = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onAfterHandle = () => {
    onGetListArticles()
  }

  const onGetListArticles = useCallback(() => {
    const params = {
      search,
      page,
      size: limit,
      sorts: "createdDate:DESC"
    }

    setFetching(true)

    getListArticleHealthCare(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data

        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit])

  useEffect(() => {
    onGetListArticles()
  }, [onGetListArticles])

  const renderArticleType = (type: number) => {
    switch (type) {
      case ARTICLE_TYPES.FREE:
        return "Free"
      case ARTICLE_TYPES.CHARGE_COST:
        return "Charge Cost"
      default:
        return " "
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Health Care
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Title'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List Article Health Care</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'articles' : 'article'}`}</span>
          </h3>
          <Button
            onClick={() => refModalHandleArticle.current.open()}
          >
            + Add new article
          </Button>
        </div>
        <div className='card-body py-3 health-care-page'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>title</th>
                    <th className='min-w-150px'>Time Read</th>
                    <th className='min-w-150px'>List Category</th>
                    <th className='min-w-200px'>Content</th>
                    <th className='min-w-150px text-center'>BackGround Image</th>
                    <th className='min-w-1500px text-center'>Thumbnail</th>
                    <th className='min-w-150px text-center'>Type Article</th>
                    <th className='min-w-120px'>Price</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='pe-4 min-w-100px '>Actions</th>
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={index} >
                          <td className="ps-4">
                            {item.title}
                          </td>
                          <td>
                            {(item.timeRead).toString().concat(' mins')}
                          </td>
                          <td>
                            <div className='text-2-lines'>
                              {stringListCategoryArticle(item.articleCategory || [])}
                            </div>
                          </td>
                          <td className='content'>
                            <div dangerouslySetInnerHTML={{ __html: item.content }} className='text-2-lines' />
                          </td>
                          <td className='text-center'>
                            <img src={item.imageUrl} className='box-img' />
                          </td>
                          <td className='text-center'>
                            <img src={item.thumbnailUrl} className='box-img' />
                          </td>
                          <td className='text-center'>
                            {renderArticleType(item.type)}
                          </td>
                          <td>
                            {item.price}
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                checked={item.status === STATUS_CHECK.ACTIVE}
                                onChange={() => refModalChangeStatus.current.open(item)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-shrink-0'>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Detail Article"
                                onClick={() => refModalDetailArticle.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
                              </button>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Update Article"
                                onClick={() => refModalHandleArticle.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen055.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Delete Article"
                                onClick={() => refModalDeleteArticle.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalDelete
        title="Delete Article Health Care"
        ref={refModalDeleteArticle}
        onAfterHandle={onAfterHandle}
        onSubmitDelete={(id) => deleteArticleHealthCare(id)}
        contentDelete="Are you sure to delete this article?"
      />
      <ModalHandleArticleHealthCare onAfterHandle={onAfterHandle} ref={refModalHandleArticle} />
      <ModalChangeStatus
        ref={refModalChangeStatus}
        onAfterChange={() => {
          onGetListArticles()
        }}
        onSubmitChangeStatus={(id) => changeStatusArticleHealthCare(id)}
        contentChangeStatus="Are you sure to change status ?"
      />
      <ModalDetailArticleHealCare ref={refModalDetailArticle} />
    </>
  )
}

export { HealthCare }
