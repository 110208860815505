import clsx from 'clsx'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { check2ArrayPermission } from '../../../../app/utils/funcs'
import { RootState } from '../../../../setup'
import { checkIsActive, KTSVG } from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  permissions: number[]
}

const AsideMenuItemWithSub: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  permissions
}) => {
  const permissionsAccount: any = useSelector<RootState>(({ auth }) => auth.user?.permissions, shallowEqual)

  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  // const { config } = useLayout()
  // const { aside } = config

  if (!check2ArrayPermission(Object.keys(permissionsAccount), permissions)) {
    return null
  }

  return (
    <div
      className={clsx('menu-item', { 'here show': isActive }, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && (
          <div className="menu-icon">
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </div>
        )}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
        {children}
      </div>
    </div>
  )
}

export { AsideMenuItemWithSub }
