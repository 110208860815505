/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { formatDate } from "../../utils/funcs";
import {getListArtists} from "../../apis/artistApis";
import {KTSVG} from "../../../_metronic/helpers";
import {ModalApproveArtist} from "./ModalApproveArtist";
import {ModalRejectArtist} from "./ModalRejectArtist";
import {ARTIST_STATUS} from "../../constants";

let timeout: any

const Artist: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const refModalApproveArtist = useRef<any>(null)
  const refModalRejectArtist = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onAfterHandleUser = () => {
    onGetListArtist()
  }

  const onAfterDelete = () => {
    if (page === 1) {
      onGetListArtist()
    } else {
      setPage(1)
    }
  }

  const onGetListArtist = useCallback(() => {
    const params = {
      search,
      page,
      size: limit
    }


    setFetching(true)

    getListArtists(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data

        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit])

  useEffect(() => {
    onGetListArtist()
  }, [onGetListArtist])

  const returnStatusArtist = (status: number) => {
    switch(status){
      case ARTIST_STATUS.REJECT :
        return "REJECT"
      case ARTIST_STATUS.REQUEST: 
        return "REQUEST"
      case ARTIST_STATUS.APPROVE:
        return "APPROVED"
    }
  }


  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Artist
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Email, fullname...'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List Artist Request</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'companies' : 'company'}`}</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>Email</th>
                    <th className='min-w-200px'>Username</th>
                    <th className='min-w-150px'>FullName</th>
                    <th className='min-w-120px'>Phone</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='min-w-120px'>Created By</th>
                    <th className='pe-4 min-w-100px text-end rounded-end'>Actions</th>
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.userId}>
                          <td className="ps-4">
                            {item.user.email}
                          </td>
                          <td>
                            {item.user.username}
                          </td>
                          <td>
                            {item.user.firstName} {item.user.lastName}
                          </td>
                          <td>
                            {item.user.phone}
                          </td>
                          <td>
                            {returnStatusArtist(item.status)}
                          </td>
                          <td>
                            <div>
                              {item && formatDate(item.lastModifiedDate)}
                            </div>
                          </td>
                          <td>
                            {item.status === 1 && (
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                  <button
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title="Reject Artist"
                                      onClick={() => refModalRejectArtist.current.open(item)}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  >
                                    <KTSVG path='/media/icons/duotune/general/gen036.svg' className='svg-icon-3' />
                                  </button>
                                  <button
                                      data-bs-toggle='tooltip'
                                      data-bs-placement='top'
                                      title="Approve Artist"
                                      onClick={() => refModalApproveArtist.current.open(item)}
                                      className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                  >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen037.svg'
                                        className='svg-icon-3'
                                    />
                                  </button>
                                </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalApproveArtist onAfterHandle={onAfterHandleUser} ref={refModalApproveArtist} />
      <ModalRejectArtist onAfterHandle={onAfterDelete} ref={refModalRejectArtist} />
    </>
  )
}

export { Artist }
