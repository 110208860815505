import axios from 'axios'

interface IPreUploadFile {
  fileName: string
  contentType: string
}

interface IUploadFile {
  urlUpload: string
  file: any
}

export interface IResPreUploadFile {
  urlEndpoint: string
  urlUpload: string
}


const commonApis = {
  preUploadFile: ({ fileName, contentType }: IPreUploadFile) =>
    axios.post<IResPreUploadFile>(`/api/mobile/common/create-presigned-url`, { fileName, contentType }),
  uploadFile: ({ urlUpload, file }: IUploadFile) => {

    return fetch(urlUpload, {
      method: 'PUT',
      body: file
    });
  },
};

export default commonApis;
