/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { getListUsers } from '../../apis/userApis'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { ModalDeleteUser } from './ModalDeleteUser'
import { ModalHandleUser } from './ModalHandleUser'
import { formatDate } from "../../utils/funcs";
import { Button } from 'react-bootstrap-v5'
import { USER_TYPE } from '../../constants'

let timeout: any

const User: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [searchUserType, setSearchUserType] = useState<number | null>(null)


  const refModalHandleUser = useRef<any>(null)
  const refModalDeleteUser = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onGetListUsers = useCallback(() => {
    const params = {
      search,
      page,
      size: limit,
      sorts: "createdDate:DESC",
      userType: searchUserType
    }

    setFetching(true)

    getListUsers(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data

        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit, searchUserType])

  useEffect(() => {
    onGetListUsers()
  }, [onGetListUsers])

  const onAfterHandleUser = () => {
    onGetListUsers()
  }

  // const onAfterDelete = () => {
  //   if (page === 1) {
  //     onGetListUsers()
  //   } else {
  //     setPage(1)
  //   }
  // }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        User
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Name, phone, address...'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
            <div className="col-md-6">
              <select
                onChange={(e) => setSearchUserType(+e.target.value)}
                className='form-select form-control-lg form-select-solid flex-1'
              >
                <option>All User Type</option>
                <option value={USER_TYPE.ARTIST}>Artist</option>
                <option value={USER_TYPE.USER}>User</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List User</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'companies' : 'company'}`}</span>
          </h3>
          <Button
            onClick={() => refModalHandleUser.current.open()}
          >
            + Add new user
          </Button>
        </div>
        <div className='card-body py-3'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>Email</th>
                    <th className='min-w-200px'>Username</th>
                    <th className='min-w-150px'>User Type</th>
                    <th className='min-w-120px'>Reference Code</th>
                    <th className='min-w-150px'>Created Date</th>
                    {/* <th className='pe-4 min-w-100px text-end rounded-end'>Actions</th> */}
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.id}>
                          <td className="ps-4">
                            {item.email}
                          </td>
                          <td>
                            {item.username}
                          </td>
                          <td>
                            {
                              item?.artist ?
                                "Artist"
                                :
                                "User"
                            }
                          </td>
                          <td>
                            {item.referenceCode}
                          </td>
                          <td>
                            {item && formatDate(item.createdDate)}
                          </td>
                          {/* <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Edit User"
                                onClick={() => refModalHandleUser.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                              </button>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Delete User"
                                onClick={() => refModalDeleteUser.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalHandleUser onAfterHandle={onAfterHandleUser} ref={refModalHandleUser} />
      {/* <ModalDeleteUser onAfterHandle={onAfterDelete} ref={refModalDeleteUser} /> */}
    </>
  )
}

export { User }
