import React, { useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import commonApis from '../apis/commonApis';
import errorHelper from '../utils/error-helper';

interface IUploadImg {
  onChange: (url: string) => void
  value: string
  error?: string
}
const UploadImg = ({ value, onChange, error }: IUploadImg) => {
  const [loading, setLoading] = useState(false)
  const refFile = useRef<any>()

  const onChooseImg = async (e: any) => {
    if (e.target.files.length) {
      const file = e.target.files[0]

      let urlImg = ''

      setLoading(true)
      commonApis.preUploadFile({
        fileName: file.name,
        contentType: file.type,
      })
        .then(({ data: resPreUpload }) => {
          const { urlEndpoint, urlUpload } = resPreUpload
          urlImg = urlEndpoint
          return commonApis.uploadFile({
            urlUpload,
            file,
          })
        })
        .then(() => {
          onChange(urlImg)
        })
        .catch((err) => {
          errorHelper(err)
          setLoading(false)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <span className='indicator-progress' style={{ display: 'block' }}>
          <span className='spinner-border spinner-border-sm align-middle'></span>
        </span>
      </div>
    )
  }

  return (
    <div>
      {value ? (
        <button onClick={() => {
          onChange('')
        }} className='btn btn-light p-0 symbol symbol-100px symbol-lg-100px symbol-fixed'>
          <img src={value} alt='Avatar Bot' />
        </button>
      ) : (
        <div className='d-flex'>
          <button
            type='button'
            onClick={() => refFile.current.click()}
            className='btn btn-light-white symbol symbol-100px symbol-lg-100px symbol-fixed'
          >
            <img
              src={toAbsoluteUrl('/media/icons/duotune/abstract/abs011.svg')}
              alt=''
              className='shadow-sm border border-1 rounded-3 border p-5'
            />
          </button>
          {error && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block text-center'>
                <span role='alert'>{error}</span>
              </div>
            </div>
          )}
        </div>
      )}
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/*"
        onChange={onChooseImg}
        ref={refFile}
      />
    </div>
  )
}

export {
  UploadImg
};
