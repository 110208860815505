import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from "yup";
import { KTSVG } from '../../../_metronic/helpers';
import { getListAllFieldOfActivity } from '../../apis/fieldOfActivity';
import { getListAllJobs, getListJobs } from '../../apis/jobApis';
import { createUser, updateUser } from '../../apis/userApis';
import { CONTENT_TYPE, USER_TYPE } from '../../constants';
import errorHelper from '../../utils/error-helper';
import { formatNumber } from '../../utils/funcs';
import successHelper from '../../utils/success-helper';

type Props = {
  onAfterHandle: (data?: any) => void
}

interface FormData {
  email: string | undefined
  username: string | undefined
  referralCode: string | undefined
  jobId: number | null
  fieldOfActivityId: number | null
  userType: number | null
  topic: string
}

interface TypeData extends FormData {
  id: number | undefined
}

const schema = yup.object({
  email: yup.string().email('Invalid Email').max(100).required(),
  username: yup.string().matches(/^([a-zA-Z0-9]*[a-zA-Z]+[0-9]*)$/g, 'Invalid Username').max(50).required(),
  userType: yup.number().required(),
  jobId: yup.number()
    .nullable()
    .transform((_, val) => val ? Number(val) : null)
    .when('userType', {
      is: USER_TYPE.ARTIST,
      then: yup.number().required()
    })
  ,
  fieldOfActivityId: yup
    .number()
    .nullable()
    .transform((_, val) => val ? Number(val) : null)
    .when('userType', {
      is: USER_TYPE.ARTIST,
      then: yup.number().required()
    })
})

const ModalHandleUser = forwardRef(({ onAfterHandle }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<TypeData | null>(null)
  const [loading, setLoading] = useState(false)
  const [listJobs, setListJobs] = useState<any[]>([])
  const [listFieldOfActivities, setListFieldOfActiviies] = useState<any[]>([])
  const [fetching, setFetching] = useState(false)
  const [errorEmailUser, setErrorEmailUser] = useState("")
  const [errorUsernameUser, setErrorUsernameUser] = useState("")


  const { register, handleSubmit, formState: { errors }, setValue, reset, control } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  const { userType } = useWatch({ control })

  useImperativeHandle(ref, () => ({
    open(dataCompany: any) {
      setVisible(true)
      // setData(dataCompany)
      // setValue('name', dataCompany?.name)
      // setValue('phone', dataCompany?.phone)
      // setValue('address', dataCompany?.address)
      // setValue('description', dataCompany?.description)
    }
  }))

  useEffect(() => {
    getListAllFieldOfActivity()
      .then(res => setListFieldOfActiviies(res.data))
  }, [])
  useEffect(() => {
    getListAllJobs()
      .then(res => setListJobs(res.data))
  }, [])

  const onSubmit = (values: any) => {
    const { email, username, userType, jobId, fieldOfActivityId, topic, referralCode }: any = values
    let payload = {}
    if (userType === USER_TYPE.ARTIST) {
      payload = {
        email,
        username,
        userType,
        jobId,
        fieldOfActivityId,
        // topic
        referralCode
      }
    } else {
      payload = {
        email,
        username,
        userType,
        // topic
        referralCode
      }
    }

    setLoading(true)
    if (data) {
      // updateUser(name, phone, userType, description, data.id)
      //   .then(({ data }) => {
      //     successHelper('Update User Success!')
      //     onAfterHandle(data)
      //     onClose()
      //   })
      //   .catch((err) => {
      //     errorHelper(err)
      //   })
      //   .finally(() => {
      //     setLoading(false)
      //   })
    } else {
      createUser(payload)
        .then(({ data }) => {
          successHelper('Create User Success!')
          onAfterHandle(data)
          onClose()
        })
        .catch((err) => {
          console.log(err.response)
          switch (err?.response?.data[0].code) {
            case "EMAIL_IS_EXISTED":
              return setErrorEmailUser("Email is Existed")
            case "USERNAME_IS_EXISTED":
              return setErrorUsernameUser("Username is Existed")
            default:
              return errorHelper(err)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const onClose = () => {
    setVisible(false)
    setData(null)
    reset()
    setErrorEmailUser('')
    setErrorUsernameUser('')
  }
  if (userType) {
    console.log(userType, typeof (userType))
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='modal-md'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {data ? 'Update User' : 'Create User'}
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        <div className='modal-body'>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              {...register("email")}
              name='email'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.email },
              )}
              onChange={() => setErrorEmailUser('')}
              autoComplete='off'
            />
            {errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.email.message}</span>
                </div>
              </div>
            )}
            {errorEmailUser && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorEmailUser}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Username</label>
            <input
              placeholder='Username'
              {...register("username")}
              name='username'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.username },
              )}
              onChange={() => setErrorUsernameUser('')}
              autoComplete='off'
            />
            {errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.username.message}</span>
                </div>
              </div>
            )}
            {errorUsernameUser && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errorUsernameUser}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>User Type</label>
            <select
              placeholder='User or Artist'
              {...register("userType")}
              name='userType'
              className={clsx(
                'form-select form-select-solid',
                { 'is-invalid': errors.userType },
              )}
            >
              <option value={USER_TYPE.USER}>User</option>
              <option value={USER_TYPE.ARTIST}>Artist</option>
            </select>
            {errors.userType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.userType.message}</span>
                </div>
              </div>
            )}
          </div>

          {
            userType == USER_TYPE.ARTIST &&
            <>
              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Job</label>
                <select
                  placeholder='Job'
                  {...register("jobId")}
                  name='jobId'
                  className={clsx(
                    'form-select form-select-solid',
                    { 'is-invalid': errors.jobId },
                  )}
                >
                  {
                    listJobs?.map((job, index) => (
                      <option
                        key={index}
                        value={job.id}
                      >
                        {job.name}
                      </option>
                    ))
                  }
                </select>
                {errors.jobId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.jobId.message}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>Field Of Activity</label>
                <select
                  placeholder='Field Of Activity'
                  {...register("fieldOfActivityId")}
                  name='fieldOfActivityId'
                  className={clsx(
                    'form-select form-select-solid',
                    { 'is-invalid': errors.fieldOfActivityId },
                  )}
                >
                  {
                    listFieldOfActivities?.map((activity, index) => (
                      <option
                        key={index}
                        value={activity.id}
                      >
                        {activity.name}
                      </option>
                    ))
                  }
                </select>
                {errors.fieldOfActivityId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.fieldOfActivityId.message}</span>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Referral Code (Optinal)</label>
            <input
              placeholder='Referral Code'
              {...register("referralCode")}
              name='referralCode'
              onChange={(e) => setValue('referralCode', formatNumber(e.target.value), { shouldValidate: true })}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.referralCode },
              )}
              autoComplete='off'
            />
            {errors.referralCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.referralCode.message}</span>
                </div>
              </div>
            )}
          </div>
          {/* <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Topic (Optinal)</label>
            <input
              placeholder='Topic'
              {...register("topic")}
              name='topic'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.topic },
              )}
              autoComplete='off'
            />
            {errors.topic && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.topic.message}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='modal-footer d-flex justify-content-center'>
            <button type="reset" className='btn btn-light-primary' onClick={onClose}>
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Apply</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
})

export { ModalHandleUser };

