/* eslint-disable jsx-a11y/anchor-is-valid */
import { formatDateTimeRange } from '@formatjs/intl/src/dateTime'
import moment from 'moment'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap-v5'
import { FormattedDateTimeRange } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { getListCheckMe } from '../../apis/checkMeApis'
import { getListTopics } from '../../apis/topicApis'
import { Pagination } from '../../components/Pagination'
import RangePicker from '../../components/RangePicker'
import { ARTIST_STATUS, RANGE_DATE } from '../../constants'
import errorHelper from '../../utils/error-helper'

let timeout: any
const CheckMe: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [dateRange, setDateRange] = useState(['', ''])

  const [startTime, endTime] = dateRange

  const onChangeSearch = (value: string ) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onGetListTopics = useCallback(() => {
    const params = {
      search,
      fromDate: startTime, 
      toDate: endTime,
      page,
      size: limit
    }
    setFetching(true)
    getListCheckMe(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data
        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit, dateRange])

  useEffect(() => {
    onGetListTopics()
  }, [onGetListTopics])

  const onChangeRangeDate = (range:number) => {
    let startTime
    let endTime
    let date = new Date()
    switch(range){
      case RANGE_DATE.TODAY:
        startTime = moment(date).format("yyyy-MM-DD")
        endTime =  moment(date).format("yyyy-MM-DD")
        return setDateRange([startTime, endTime])
      case RANGE_DATE.WEEK:
        startTime = moment(moment().startOf('week').toDate()).format("yyyy-MM-DD")
        endTime =  moment(moment().endOf('week').toDate(),).format("yyyy-MM-DD")
        return setDateRange([startTime, endTime])
      case RANGE_DATE.MONTH: 
        startTime = moment(moment().startOf('month').toDate()).format("yyyy-MM-DD")
        endTime =  moment(moment().endOf('month').toDate(),).format("yyyy-MM-DD")
        return setDateRange([startTime, endTime])
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Check me
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Full name ...'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
            <div className="col-md-6">
              <div>
              <RangePicker
                onChange={([startTime, endTime]) => {
                  if ((startTime && endTime)
                    || (!startTime && !endTime)) {
                    setPage(1)
                    setDateRange([startTime, endTime])
                  }
                }}
                selectsRange={[startTime, endTime]}
                onChangeRaw={(e) => e.preventDefault()}
                onKeyDown={(e) => e.preventDefault()}
                maxTime={moment().endOf('day').toDate()}
                dayClassName={(date) => date.getTime() > new Date().getTime()
                  ? 'disabled-date'
                  : undefined}
                inputFormat="YYYY-MM-DD"
              />
              </div>
              <div className='d-flex mt-2'>
                <Button 
                  className='m-1'
                  onClick={()=>onChangeRangeDate(RANGE_DATE.TODAY)}
                >
                  Today
                </Button>
                <Button 
                  className='m-1'
                  onClick={()=>onChangeRangeDate(RANGE_DATE.WEEK)}
                  >
                  Week
                </Button>
                <Button 
                  className='m-1'
                  onClick={()=>onChangeRangeDate(RANGE_DATE.MONTH)}
                  >
                  Month
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List check me</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'topics' : 'topic'}`}</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                <tr className='fw-bolder text-muted'>
                  <th className='ps-4 min-w-100px rounded-start'>id</th>
                  <th className='min-w-200px'>Full name</th>
                  <th className='min-w-100px'>Role</th>
                  <th className='min-w-100px'>Times</th>
                  <th className='min-w-300px'>
                    <div className='d-flex justify-content-center mb-3'>Result</div>
                    <div className='d-flex justify-content-around'>
                      <div className='min-w-50px d-flex justify-content-center'>Happy</div>
                      <div className='min-w-50px d-flex justify-content-center'>So so</div>
                      <div className='min-w-50px d-flex justify-content-center'>Bad</div>
                    </div>
                  </th>
                  <th className='min-w-200px'>Date</th>
                </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                    {list.map((item) => (
                      <tr key={item.id}>
                        <td className="ps-4">
                          {item.id}
                        </td>
                        <td>
                          {item.user?.firstName} {item.user?.lastName}
                        </td>
                        {item.artist?.status === ARTIST_STATUS.APPROVE ?
                            <td>Artist</td>
                          :
                            <td>User</td>
                        }
                        <td>
                          {item.totalCheckMe}
                        </td>
                        <td>
                          <div className='d-flex justify-content-around'>
                            <div className='min-w-50px d-flex justify-content-center'>{item.numOfHappy}</div>
                            <div className='min-w-50px d-flex justify-content-center'>{item.numOfSoSo}</div>
                            <div className='min-w-50px d-flex justify-content-center'>{item.numOfBad}</div>
                          </div>
                        </td>
                        <td>
                          {moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
    </>
  )
}

export { CheckMe }
