import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { onActiveEmail } from '../redux/AuthCRUD'
import queryString from 'query-string';
import errorHelper from '../../../utils/error-helper';

type TypeParam = {
  token?: string,
  email?: string
}

export function ActiveEmail() {
  const [isActive, setIsActive] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const params: TypeParam = queryString.parse(location.search)
  const { email, token } = params

  const onDirectLogin = () => {
    history.push('/auth/login')
  }

  const onVerifyEmail = useCallback(() => {
    onActiveEmail(email, token)
      .then((res) => {
        setIsActive(true)
      })
      .catch((err) => {
        errorHelper(err)
      })
  }, [email, token])

  useEffect(() => {
    if (!email || !token) {
      history.replace('/auth/login')
    } else {
      onVerifyEmail()
    }
  }, [email, token, history, onVerifyEmail])

  return (
    <div
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      id='kt_login_signup_form'
    >
      {!isActive ? (
        <div className='d-flex justify-content-center'>
          <span className='indicator-progress' style={{ display: 'block' }}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        </div>
      ) : (
        <div className='d-flex flex-column'>
          <h5 className='text-center mb-5'>Your account has been activated</h5>
          <button className='btn btn-primary' onClick={onDirectLogin}>
            Login now
          </button>
        </div>
      )}
    </div>
  )
}
