import axios from 'axios'

interface TypeResReport {
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_REPORT_URL = `/api/report`
export const APPROVE_REJECT_REPORT_URL = `/api/report`


export function getListReport(params: TypeParamsGetJob) {
  return axios.get<TypeResReport>(GET_LIST_REPORT_URL, { params })
}

export function approveOrRejectReport(id: number | undefined, status: number) {
  return axios.put(`${APPROVE_REJECT_REPORT_URL}/${id}`, { status })
}

