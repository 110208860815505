import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../_metronic/helpers';
import { stringListHashtags } from '../../utils/funcs';

type Props = {}

interface TypeData {
  id: number,
  title: string,
  imageUrl: string,
  content: any,
  timeRead: number,
  articleHashtag: []
}

const ModalDetailArticleHealCare = forwardRef(({ }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<TypeData | null>(null)

  useImperativeHandle(ref, () => ({
    open(dataArticle: any) {
      setData(dataArticle)
      setVisible(true)
    }
  }))

  const onClose = () => {
    setData(null)
    setVisible(false)
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='mw-450px h-auto'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        className='modal-content detail-article-page'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            Detail Article Health Care
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        {
          data && (
            <div className='modal-body'>
              <div className='title'>
                {data?.title}
              </div>
              <div className='time-read'>
                {(data?.timeRead)?.toString().concat(' mins')}
              </div>
              <img
                src={data?.imageUrl}
                className='background-img'
              />
              <div
                dangerouslySetInnerHTML={{ __html: data?.content }}
                className='content'
              />
              <div
                className='hashtag'
              >
                {stringListHashtags(data?.articleHashtag)}
              </div>
            </div>
          )
        }
        <div className='modal-footer d-flex justify-content-center'>
          <button type="reset" className='btn btn-light-primary' onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  )
})

export { ModalDetailArticleHealCare };

