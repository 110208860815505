import React, { forwardRef, useRef } from 'react'
import classNames from 'classnames'

interface IInput {
    className: string,
    value: any,
    onChange: any
  }

  
const Input = ({ className, ...props }:IInput) => {
  const ref = useRef<any>()
  return (
    <input
      {...props}
      ref={ref}
      className={classNames('form-control input-custom', className)}
    />
  )
}

export default forwardRef(Input)