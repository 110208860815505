import React, { Suspense } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { RootState } from '../../setup'
import { FallbackView } from '../../_metronic/partials'
import { PERMISSION } from '../constants'
import { User } from '../pages/user/User'
import { check2ArrayPermission } from '../utils/funcs'
import { Artist } from "../pages/artist/Artist";
import { Topic } from '../pages/topic/Topic'
import { Job } from '../pages/job/Job'
import { CheckMe } from '../pages/checkme/CheckMe'
import { Report } from '../pages/report/Report'
import { CategoryType } from "../pages/category-type/CategoryType";
import { HashTag } from "../pages/hashtag/HashTag";
import { HealthCare } from '../pages/health-care/HealthCare'
import { SplashPopup } from '../pages/splash-popup/SplashPopup'

export function PrivateRoutes() {
  const permissions: any = useSelector<RootState>(({ auth }) => auth.user?.permissions, shallowEqual)

  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.USER.READ,
          PERMISSION.USER.CREATE,
          PERMISSION.USER.UPDATE,
          PERMISSION.USER.DELETE,
        ]) && (
            <Route path='/user' component={User} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.ARTIST.READ,
          PERMISSION.ARTIST.APPROVED_REJECT,
        ]) && (
            <Route path='/artist' component={Artist} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.TOPIC.READ,
        ]) && (
            <Route path='/topic' component={Topic} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.JOB.READ,
        ]) && (
            <Route path='/job' component={Job} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.JOB.READ,
        ]) && (
            <Route path='/check-me' component={CheckMe} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.CATEGORY_TYPE.READ,
        ]) && (
            <Route path='/category-type' component={CategoryType} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.HASHTAG.READ,
        ]) && (
            <Route path='/hashtag' component={HashTag} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.REPORT.READ,
        ]) && (
            <Route path='/report' component={Report} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.USER.READ,
          PERMISSION.USER.CREATE,
          PERMISSION.USER.DELETE,
          PERMISSION.USER.UPDATE,
        ]) && (
            <Route path='/health-care' component={HealthCare} />
          )}
        {check2ArrayPermission(Object.keys(permissions) || [], [
          PERMISSION.USER.READ,
          PERMISSION.USER.CREATE,
          PERMISSION.USER.DELETE,
          PERMISSION.USER.UPDATE,
        ]) && (
            <Route path='/notify-splash' component={SplashPopup} />
          )}
        <Redirect from='/auth' to='/' />
        <Redirect exact from='/' to='/user' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
