import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { KTSVG } from '../../../_metronic/helpers';
import errorHelper from '../../utils/error-helper';
import successHelper from '../../utils/success-helper';
import {createCategoryType, updateCategoryType} from "../../apis/categoryTypeApis";

type Props = {
  onAfterHandle: (data?: any) => void
}

interface FormData {
  name: string | undefined
}

interface TypeData extends FormData {
  id: number | undefined
}

const schema = yup.object({
  name: yup.string().max(255, 'Maximum 255 symbols').required('Required').trim(),
}).required();

const ModalHandleCategoryType = forwardRef(({ onAfterHandle }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<TypeData | null>(null)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<FormData>({
    resolver: yupResolver(schema),
  })

  useImperativeHandle(ref, () => ({
    open(dataJob: any) {
      setVisible(true)
      setData(dataJob)
      setValue('name', dataJob?.name)
    }
  }))

  const onSubmit = (values: any) => {
    setLoading(true)
    if (data) {
      updateCategoryType(values, data.id)
          .then(({ data }) => {
            successHelper('Update Category Type Success!')
            onAfterHandle(data)
            onClose()
          })
          .catch((err) => {
            errorHelper(err)
          })
          .finally(() => {
            setLoading(false)
          })
    } else {
      createCategoryType(values)
          .then(({ data }) => {
            successHelper('Create Category Type Success!')
            onAfterHandle(data)
            onClose()
          })
          .catch((err) => {
            errorHelper(err)
          })
          .finally(() => {
            setLoading(false)
          })
    }
  }

  const onClose = () => {
    setVisible(false)
    setData(null)
    reset()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='modal-md'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {data ? 'Update HashTag' : 'Create HashTag'}
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        <div className='modal-body'>
          <div className='fv-row mb-10'>
            <label className='form-label fs-6 fw-bolder text-dark'>Name</label>
            <input
              placeholder='Name'
              {...register("name")}
              name='name'
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': errors.name },
              )}
              autoComplete='off'
            />
            {errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name.message}</span>
                </div>
              </div>
            )}
          </div>
          <div className='modal-footer d-flex justify-content-center'>
            <button type="reset" className='btn btn-light-primary' onClick={onClose}>
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary' disabled={loading}>
              {!loading && <span className='indicator-label'>Apply</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
})

export { ModalHandleCategoryType };

