import React from 'react'
import ReactPaginate from 'react-paginate';

interface IPagination {
  onPageChange: (page: { selected: number }) => void
  totalPage: number
  currentPage: number
}

const Pagination = ({ onPageChange, totalPage = 0, currentPage = 1 }: IPagination) => (
  <div className='pagination-style'>
    <ReactPaginate
      forcePage={+currentPage - 1}
      breakLabel="..."
      nextLabel={<i className='next'></i>}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      nextClassName='page-item next'
      nextLinkClassName='page-link'
      pageCount={Math.ceil(totalPage)}
      containerClassName="pagination"
      activeClassName="page-item active"
      pageLinkClassName="page-link"
      pageClassName="page-item"
      previousClassName='page-item previous'
      previousLinkClassName='page-link'
      previousLabel={<i className='previous'></i>}
    />
  </div>
)

export {
  Pagination
}