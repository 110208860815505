import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `/api/information`
export const LOGIN_URL = `/api/sign-in`
export const REGISTER_URL = `/api/register`
export const REQUEST_PASSWORD_URL = `/api/forgot-password/send-mail`
export const EMAIL_VERIFY_URL = `/api/email-verify`
export const VERIFY_EMAIL_FORGOT_PASS_URL = `/api/api/forgot-password/verify-token`
export const VERIFY_NEW_PASSWORD_URL = `/api/forgot-password/reset`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, fullName: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    fullName,
    password
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email
  })
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

export function onActiveEmail(email: string | undefined, token: string | undefined) {
  return axios.post(EMAIL_VERIFY_URL, {email, token})
}

export function onVerifyTokenForgotPassword(token: string | undefined) {
  return axios.get(VERIFY_EMAIL_FORGOT_PASS_URL, { params: { token } })
}

export function onVerifyNewPassword(token: string | undefined, password: string | undefined, rePassword: string | undefined) {
  return axios.post(VERIFY_NEW_PASSWORD_URL, { token, password, rePassword })
}
