import React, { useRef, useState } from "react"
import AsyncCreatableSelect from "react-select/async-creatable"
import { getListAllTopics } from "../apis/topicApis"
import { CATEGORY_TYPE } from "../constants"
import errorHelper from "../utils/error-helper"
import { genId } from "../utils/funcs"
import { REACT_STYLE } from "./select/constants"

export interface IOptionSelect {
  readonly name: string
  readonly id: number
}

interface ICategoryArticleSelect {
  acceptCreate?: boolean
  onChangeValue: (value: IOptionSelect[]) => void
  value: IOptionSelect[]
}

let timeout: any

const CategoryArticleSelect = ({ acceptCreate = true, onChangeValue, value }: ICategoryArticleSelect) => {
  const [groups, setGroups] = useState<IOptionSelect[]>([])

  const refModalHandleCategoryArticle = useRef<any>()
  const refSelect = useRef<any>()
  const [keysSelect, setKeySelect] = useState(genId())

  const onGetGroups = (inputText: string, callback: any) => {
    const params = {
      search: inputText,
      categoryTypeId: CATEGORY_TYPE.ARTICLE
    }
    clearTimeout(timeout)
    // if (inputText) {
    //   onChangeValue([])
    // }
    timeout = setTimeout(() => {
      return getListAllTopics(params)
        .then((res) => {
          const { data } = res
          setGroups(data)
          callback(data)
        })
        .catch((err) => {
          errorHelper(err)
        })
    }, 500)
  }

  const handleCheckAll = () => {
    if (groups.length !== 0) {
      onChangeValue(groups)
    } else {
      onChangeValue([])
    }
  }

  const onAfterHandleCategoryArticle = (newContact: IOptionSelect) => {
    setKeySelect(genId())
    onChangeValue([...value, newContact])
  }

  return (
    <div className="pagination-style">
      <div className="d-flex align-items-start">
        <AsyncCreatableSelect
          // createOptionPosition="first"
          key={keysSelect}
          allowCreateWhileLoading={false}
          isClearable
          isValidNewOption={() => true}
          ref={refSelect}
          className="w-100 my-react-select"
          classNamePrefix="my-select"
          placeholder="Choose category"
          value={value}
          onChange={(newValue: any) => onChangeValue(newValue)}
          closeMenuOnSelect={false}
          isMulti
          isSearchable
          loadOptions={onGetGroups}
          getOptionLabel={(e: any) => e.name}
          getOptionValue={(e: any) => e.id}
          getNewOptionData={(inputValue: string, optionLabel: any): any => ({
            id: optionLabel,
            name: groups.length === 0 ? "Cancel choose" : "Choose all category",
            __isNew__: true,
          })}
          cacheOptions={false}
          defaultOptions
          onCreateOption={(inputValue) => {
            setKeySelect(genId())
            handleCheckAll()
          }}
          styles={{
            multiValue: (state) => ({
              ...state,
              backgroundColor: "#4ebeff",
              color: "#fff",
              borderRadius: 8,
            }),
            multiValueLabel: (state) => ({
              ...state,
              color: "#fff",
              fontSize: 14,
              padding: "5px 7px",
            }),
            ...REACT_STYLE,
            multiValueRemove: (state) => ({
              "width": "30px",
              "display": "flex",
              "justifyContent": "center",
              "alignItems": "center",
              "borderRadius": 8,
              ":hover": {
                backgroundColor: "#f79501",
              },
            }),
          }}
        />
      </div>
    </div>
  )
}

export { CategoryArticleSelect }
