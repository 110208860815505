import React from 'react'
import { PERMISSION } from '../../../../app/constants'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        permissions={[
          PERMISSION.USER.READ,
          PERMISSION.USER.CREATE,
          PERMISSION.USER.UPDATE,
          PERMISSION.USER.DELETE,
        ]}
        to='/user'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='User'
      />
      <AsideMenuItem
        permissions={[
          PERMISSION.ARTIST.READ,
          PERMISSION.ARTIST.APPROVED_REJECT,
        ]}
        to='/artist'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Artist Request'
      />
      <AsideMenuItemWithSub
        to='/topic'
        title='Category'
        fontIcon='bi-person-lines-fill'
        permissions={[
          PERMISSION.TOPIC.READ,
        ]}
      >
        <AsideMenuItem
          permissions={[
            PERMISSION.TOPIC.READ,
          ]}
          to='/topic'
          icon='/media/icons/duotune/finance/fin006.svg'
          title='Topic'
        />
        <AsideMenuItem
          permissions={[
            PERMISSION.JOB.READ,
          ]}
          to='/job'
          icon='/media/icons/duotune/finance/fin006.svg'
          title='Job'
        />
        {/* <AsideMenuItem
          permissions={[
            PERMISSION.CATEGORY_TYPE.READ,
          ]}
          to='/category-type'
          icon='/media/icons/duotune/finance/fin006.svg'
          title='Category Type'
        /> */}
        <AsideMenuItem
          permissions={[
            PERMISSION.HASHTAG.READ,
          ]}
          to='/hashtag'
          icon='/media/icons/duotune/finance/fin006.svg'
          title='HashTag'
        />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        permissions={[
          PERMISSION.JOB.READ,
        ]}
        to='/check-me'
        icon='/media/icons/duotune/finance/fin006.svg'
        title='Check me'
      />

      <AsideMenuItem
        permissions={[
          PERMISSION.REPORT.READ,
          PERMISSION.REPORT.APPROVE_REJECT,
        ]}
        to='/report'
        icon='/media/icons/duotune/finance/fin007.svg'
        title='Report'
      />
      <AsideMenuItem
        permissions={[
          PERMISSION.USER.READ,
          PERMISSION.USER.CREATE,
          PERMISSION.USER.DELETE,
          PERMISSION.USER.UPDATE,
        ]}
        to='/health-care'
        icon='/media/icons/duotune/finance/fin002.svg'
        title='Health Care'
      />

      <AsideMenuItemWithSub
        to='/notify'
        title='Notify'
        fontIcon='bi-bell-fill'
        permissions={[
          PERMISSION.NOTIFY.READ,
        ]}
      >
        <AsideMenuItem
          permissions={[
            PERMISSION.NOTIFY.READ,
            PERMISSION.NOTIFY.CREATE,
            PERMISSION.NOTIFY.DELETE,
          ]}
          to='/notify-splash'
          icon='/media/icons/duotune/general/gen006.svg'
          title='Splash Popup'
        />
      </AsideMenuItemWithSub>

    </>
  )
}
