import axios from 'axios'

interface TypeResCategory{
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_HASHTAG_URL = `/api/hashTag`
export const CREATE_HASHTAG_URL = `/api/hashTag`
export const UPDATE_HASHTAG_URL = `/api/hashTag`
export const DELETE_HASHTAG_URL = `/api/hashTag`


export function getListHashTag(params: TypeParamsGetJob) {
  return axios.get<TypeResCategory>(GET_LIST_HASHTAG_URL, { params })
}

export function createHashTag(name: string) {
  return axios.post(CREATE_HASHTAG_URL, name)
}

export function updateHashTag(name: string, id: number | undefined) {
  return axios.put(`${UPDATE_HASHTAG_URL}/${id}`, name)
}

export function deleteHashTag(id: number | undefined) {
  return axios.delete(`${DELETE_HASHTAG_URL}/${id}`)
}

