import axios from 'axios'

interface TypeResTopic {
  count: number
  rows: Array<{
    fullName: string
  }>
}

interface TypeParamsGetTopic {
  search?: string
  fromDate?: string
  toDate?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_CHECK_ME_URL = `/api/check-me`

export function getListCheckMe(params: TypeParamsGetTopic) {
  return axios.get<TypeResTopic>(GET_LIST_CHECK_ME_URL, { params })
}

