import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import queryString from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import * as yup from "yup";
import errorHelper from '../../../utils/error-helper'
import successHelper from '../../../utils/success-helper'
import { onVerifyNewPassword, onVerifyTokenForgotPassword } from '../redux/AuthCRUD'

type TypeParam = {
  token?: string,
}

const schema = yup.object().shape({
  password: yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Password is required').trim(),
  confirmPassword: yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Password and Confirm Password didn't match"),
    }).trim(),
})

interface FormData {
  password: string | undefined
  confirmPassword: string | undefined
}

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const params: TypeParam = queryString.parse(location.search)
  const { token } = params

  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = (values: FormData) => {
    setLoading(true)
    onVerifyNewPassword(token, values.password, values.confirmPassword)
      .then(({ data: { result } }) => {
        successHelper('Reset password success')
        history.push('/auth/login')
      })
      .catch((err) => {
        setLoading(false)
        errorHelper(err)
      })
  }

  const onVerifyToken = useCallback(() => {
    onVerifyTokenForgotPassword(token)
      .then()
      .catch(err => {
        errorHelper(err)
      })
  }, [token])

  useEffect(() => {
    if (!token) {
      history.replace('/auth/login')
    } else {
      onVerifyToken()
    }
  }, [token, history, onVerifyToken])

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Reset Password</h1>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            placeholder='Enter your password'
            type='password'
            autoComplete='off'
            {...register('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': errors && errors.password },
            )}
          />
          {errors && errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors && errors.password.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            placeholder='Enter your confirm password'
            type='password'
            autoComplete='off'
            {...register('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': errors && errors.confirmPassword },
            )}
          />
          {errors && errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errors && errors.confirmPassword.message}</span>
              </div>
            </div>
          )}
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            disabled={loading}
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='reset'
              className='btn btn-lg btn-light-primary fw-bolder'
            >
              Cancel
            </button>
          </Link>
        </div>
      </form>
    </>
  )
}
