/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { ModalDeleteTopic } from './ModalDeleteTopic'
import { ModalHandleTopic } from './ModalHandleTopic'
import { getListTopics } from '../../apis/topicApis'
import { CATEGORY_TYPE } from '../../constants'

let timeout: any

const Topic: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [searchCategoryType, setSearchCategoryType] = useState<number | null>(null)

  const refModalHandleTopic = useRef<any>(null)
  const refModalDeleteTopic = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onGetListTopics = useCallback(() => {
    const params = {
      search,
      page,
      size: limit,
      categoryTypeId: searchCategoryType
    }

    setFetching(true)

    getListTopics(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data
        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit, searchCategoryType])

  useEffect(() => {
    onGetListTopics()
  }, [onGetListTopics])

  const onAfterHandleTopic = () => {
    onGetListTopics()
  }

  const onAfterDeleteTopic = () => {
    if (page === 1) {
      onGetListTopics()
    } else {
      setPage(1)
    }
  }

  const renderCategoryType = (id: number) => {
    switch (id) {
      case CATEGORY_TYPE.ARTIST:
        return "Artist"
      case CATEGORY_TYPE.ARTICLE:
        return "Article"
      case CATEGORY_TYPE.EVENT:
        return "Event"
      case CATEGORY_TYPE.FORUM:
        return "Forum"
      default:
        return ""
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Topic
      </PageTitle>
      <div className="card mb-5">
        <div className='card-header border-0 py-5 flex-grow-1 flex-column'>
          <h3 className='card-title align-items-start'>
            <span className='card-label fw-bolder fs-3 mb-1'>Filter</span>
          </h3>
          <div className="row flex-grow-1">
            <div className="col-md-6">
              <input
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder='Name...'
                name='name'
                className='form-control form-control-lg form-control-solid flex-1'
                autoComplete='off'
              />
            </div>
            <div className="col-md-6">
              <select
                onChange={(e) => setSearchCategoryType(+e.target.value)}
                className='form-select form-control-lg form-select-solid flex-1'
              >
                <option>All Category Type</option>
                <option value={CATEGORY_TYPE.ARTIST}>Artist</option>
                <option value={CATEGORY_TYPE.ARTICLE}>Article</option>
                <option value={CATEGORY_TYPE.EVENT}>Event</option>
                <option value={CATEGORY_TYPE.FORUM}>Forum</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List Topic</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'topics' : 'topic'}`}</span>
          </h3>
          <button
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title="Create Topic"
            onClick={() => refModalHandleTopic.current.open()}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <KTSVG path='/media/icons/duotune/abstract/abs011.svg' className='svg-icon-3' />
          </button>
        </div>
        <div className='card-body py-3'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    <th className='ps-4 min-w-200px rounded-start'>id</th>
                    <th className='min-w-200px'>Name</th>
                    <th className='min-w-200px'>Category type</th>
                    <th className='pe-4 min-w-100px text-end rounded-end'>Actions</th>
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item) => (
                        <tr key={item.id}>
                          <td className="ps-4">
                            {item.id}
                          </td>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {renderCategoryType(item.categoryType)}
                          </td>
                          <td>
                            <div className='d-flex justify-content-end flex-shrink-0'>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Edit Topic"
                                onClick={() => refModalHandleTopic.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                              </button>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Delete Topic"
                                onClick={() => refModalDeleteTopic.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalHandleTopic onAfterHandle={onAfterHandleTopic} ref={refModalHandleTopic} />
      <ModalDeleteTopic onAfterHandle={onAfterDeleteTopic} ref={refModalDeleteTopic} />
    </>
  )
}

export { Topic }
