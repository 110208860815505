import moment from 'moment'
// import XLSX from 'xlsx'

export const genId = () => Math.random().toString(36).substr(2, 9)

export const checkRuleKey = (ruleCheck: string, arrKey = []) => {
  if (arrKey.length === 0) {
    return true
  }

  return arrKey.find((rule => rule === ruleCheck))
}

export const getPeriod = (expired: string) => {
  const expiredMoment = moment(expired).startOf('date')
  const now = moment().startOf('date')
  const years = expiredMoment.diff(now, 'years')
  let months = expiredMoment.diff(now, 'months') - years * 12
  let period = ''
  if (expiredMoment.isSameOrBefore(now)) {
    return 'Expired'
  }
  if (years > 0) {
    period = `${years} year${years > 1 ? 's' : ''}`
  }
  if (months > 0) {
    period += `${period ? ' ' : ''}${months} month${months > 1 ? 's' : ''}`
  }
  if (years === 0 && months === 0) {
    period = 'less than 1 month'
  }
  return period
}

export const getYearMonthPassportRemaining = (expired: string) => {
  const expiredMoment = moment(expired).startOf('date')
  const now = moment().startOf('date')
  const years = expiredMoment.diff(now, 'years')
  let months = expiredMoment.diff(now, 'months') - years * 12

  return {
    years, months
  }
}

export const scrollToId = (id: string) => {
  const element: any = document.getElementById(id)

  element.scrollIntoView({ block: 'end', behavior: 'smooth' })
}

export const formatNumber = (value: string | null) => String(value).replace(/[^0-9]/g, '')

export function currencyFormat(num: number) {
  return num?.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' đ'
}

export const numberWithCommas = (x: number) => (x || '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const findListById = (list = [], id: number) => list.find((item: any) => item.id === id)

// export const exportCsv = async (csvData = [], fileName, fileExtension = '.xlsx') => {
//   const workSheet = XLSX.utils.json_to_sheet(csvData)
//   const workBook = XLSX.utils.book_new()
//   XLSX.utils.book_append_sheet(workBook, workSheet)
//   XLSX.writeFile(workBook, fileName + fileExtension, { bookType: 'xlsx', FS:'\t' })
// }

export const check2ArrayPermission = (arrPermissionAccount: string[] = [], arrPermissionFeature: number[] = []) => {
  if (arrPermissionFeature.length === 0) {
    return true
  }

  return arrPermissionFeature.some((per) => arrPermissionAccount.includes(`action${per}`))
}
export const FNS_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function formatDate(dateObj: moment.MomentInput) {
  return moment(dateObj).format(FNS_DATE_TIME_FORMAT);
}

export function removeDuplicates(originalArray: any[], prop: string) {
  let newArray = [];
  let lookupObject: any = {};

  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export const findHashtags = (searchText: string) => {
  const regexp = /\B\#\w\w+\b/g
  const result = searchText.match(regexp);
  if (result) {
    return result
  }

  return []
}

export const stringListHashtags = (listHashtags: any) => {
  const newListHashtags = <any>[]
  listHashtags.map((item: any) => {
    newListHashtags.push(item?.hashTag?.name)
  })
  const stringListHashtags = newListHashtags.join(' ')
  return stringListHashtags
}

export const stringListCategoryArticle = (listCategoryArticle: any) => {
  const newListCategoryArticle = <any>[]
  listCategoryArticle.map((item: any) => {
    newListCategoryArticle.push(item?.category?.name)
  })
  const stringListCategoryArticle = newListCategoryArticle.join(' ')
  return stringListCategoryArticle
}