import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import InputAction from './InputAction'

interface IDatePickerCuston {
  dateFormat?: string,
  startDate?: any,
  endDate?: any,
  selectsRange?: any,
  onChange: (value: any) => void,
}

const DatePickerCustom = ({...props}:IDatePickerCuston) => {

  return (
    <DatePicker
      placeholderText={'Select date'}
      {...props}
      showYearDropdown
      disabledKeyboardNavigation
      className="datepicker__custom"
      popperClassName="datepicker__custom-popper"
      calendarClassName="datepicker__custom-container"
      disabled={false}
      customInput={(
        <InputAction
          rightAction = {
            props?.startDate || props?.endDate
              ? (
                <i
                  onClick={() => props.onChange(props?.selectsRange ? [null, null] : null)}
                  className="bi bi-x-circle-fill cursor-pointer" style={{fontSize: 25+'px'}}>
                </i>
              )
              : <i className="bi bi-calendar" style={{fontSize: 25+'px'}}></i>
          }
        />
      )}
    />
  )
}

export default DatePickerCustom