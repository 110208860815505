import { toast } from 'react-toastify';

function errorHelper(err: any) {
  const statusCode = err?.response?.data?.code || err?.code || 500
  const messageError = err?.response?.data?.message || err?.message

  if (statusCode === 401 || err?.response?.data?.message === 'Unauthorized') {
    window.location.href = '/logout'
  }

  if (statusCode === 500) {
    return toast.error('System error')
  }

  if (messageError) {
    return toast.error(messageError)
  }
}

export default errorHelper
