import { StylesConfig } from "react-select/dist/declarations/src/styles"

export interface ISelectOption {
  name: string
  value: string | number
}

export const REACT_STYLE: StylesConfig<any, any, any> = {
  control: (state, status) => ({
    ...state,
    backgroundColor: status.isFocused ? "#eef3f7" : "#F5F8FA",
    borderColor: status.isFocused ? "#eef3f7" : "#F5F8FA",
    boxShadow: "none",
  }),
  input: (state) => ({
    ...state,
    margin: "8px",
  }),
  singleValue: (state) => ({
    ...state,
    margin: "8px",
  }),
  placeholder: (state) => ({
    ...state,
    fontWeight: 500,
    color: "#5E6278",
    fontSize: "1.15rem",
    opacity: 0.55,
  }),
}
