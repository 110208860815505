import axios from 'axios'

interface TypeResReport {
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_POPUP_URL = `/api/popup`
export const CREATE_POPUP_URL = `/api/popup`
export const CHANGE_STATUS_POPUP_URL = `/api/popup`
export const DELTE_POPUP_URL = `/api/popup`
export const UPDATE_POPUP_URL = `/api/popup`

export function getListSplashPopups(params: TypeParamsGetJob) {
  return axios.get<TypeResReport>(GET_LIST_POPUP_URL, { params })
}

export function createSplashPopup(payload: object) {
  return axios.post(CREATE_POPUP_URL, payload
  )
}

export function changeStatusPopup(id: number | undefined) {
  return axios.put(`${CHANGE_STATUS_POPUP_URL}/${id}/change-status`)
}

export function deletePopup(id: number | undefined) {
  return axios.delete(`${DELTE_POPUP_URL}/${id}`)
}

export function updateSplashPopup(payload: object, id: number | undefined) {
  return axios.put(`${UPDATE_POPUP_URL}/${id}`, payload)
}
