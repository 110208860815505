/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { Pagination } from '../../components/Pagination'
import errorHelper from '../../utils/error-helper'
import { KTSVG } from "../../../_metronic/helpers";
import { STATUS_CHECK } from "../../constants";
import { Button } from 'react-bootstrap-v5';
import { ModalDelete } from '../../components/ModalDelete';
import { changeStatusPopup, deletePopup, getListSplashPopups } from '../../apis/popupApis';
import { ModalHandleSplashPopup } from './ModalHandleSplashPopup';
import { ModalChangeStatus } from '../../components/ModalChangeStatus';

let timeout: any

const SplashPopup: FC = () => {
  const [countList, setCountList] = useState(0)
  const [list, setList] = useState<any[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  const refModalHandleSplashPopup = useRef<any>(null)
  const refModalChangeStatus = useRef<any>(null)
  const refModalDeleteSplashPopup = useRef<any>(null)

  const onChangeSearch = (value: string) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setSearch(value.trim())
      setPage(1)
    }, 500)
  }

  const onAfterHandle = () => {
    onGetListSplashPopups()
  }

  const onGetListSplashPopups = useCallback(() => {
    const params = {
      search,
      page,
      size: limit,
    }

    setFetching(true)

    getListSplashPopups(params)
      .then((res) => {
        const { data } = res
        const { count, rows } = data

        setList(rows)
        setCountList(count)
      })
      .catch((err) => {
        errorHelper(err)
      })
      .finally(() => {
        setFetching(false)
      })
  }, [search, page, limit])

  useEffect(() => {
    onGetListSplashPopups()
  }, [onGetListSplashPopups])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        Splash Popup
      </PageTitle>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>List Splash Popups</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{`${countList} ${countList > 1 ? 'splash popup' : 'splash popups'}`}</span>
          </h3>
          <Button
            onClick={() => refModalHandleSplashPopup.current.open()}
          >
            + Add new splash popup
          </Button>
        </div>
        <div className='card-body py-3 health-care-page'>
          <div className="list-table">
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead className='fw-bolder text-muted bg-light'>
                  <tr className='fw-bolder text-muted'>
                    {/* <th className='ps-4 max-w-80px text-center'>STT</th> */}
                    <th className='min-w-150px text-center'>Image Popup</th>
                    <th className='min-w-120px'>Status</th>
                    <th className='pe-4 min-w-100px '>Actions</th>
                  </tr>
                </thead>
                {
                  !fetching && (
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={index} >
                          {/* <td className='text-center'>
                            {(index + 1) * page}
                          </td> */}
                          <td className='text-center'>
                            <img src={item.imageUrl} className='box-img' />
                          </td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                checked={item.status === STATUS_CHECK.ACTIVE}
                                onChange={() => refModalChangeStatus.current.open(item)}
                              />
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-shrink-0'>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Update SplashPopup"
                                onClick={() => refModalHandleSplashPopup.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen055.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                              <button
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                title="Delete SplashPopup"
                                onClick={() => refModalDeleteSplashPopup.current.open(item)}
                                className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-2'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )
                }
              </table>
            </div>
            {fetching && (
              <div className='loading-table d-flex py-5 flex-row justify-content-center align-items-center'>
                <div className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </div>
              </div>
            )}
            {!fetching && list.length === 0 && (
              <div className='text-center py-5'>
                Empty item
              </div>
            )}
          </div>
          <div className="my-5">
            <Pagination currentPage={page} totalPage={countList / limit} onPageChange={({ selected }) => setPage(selected + 1)} />
          </div>
        </div>
      </div>
      <ModalDelete
        title="Delete Splash Popup"
        ref={refModalDeleteSplashPopup}
        onAfterHandle={onAfterHandle}
        onSubmitDelete={(id) => deletePopup(id)}
        contentDelete="Are you sure to delete this splash popup?"
      />
      <ModalHandleSplashPopup onAfterHandle={onAfterHandle} ref={refModalHandleSplashPopup} />
      <ModalChangeStatus
        ref={refModalChangeStatus}
        onAfterChange={() => {
          onGetListSplashPopups()
        }}
        onSubmitChangeStatus={(id) => changeStatusPopup(id)}
        contentChangeStatus="Are you sure to change status ?"
      />
    </>
  )
}

export { SplashPopup }
