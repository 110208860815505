import axios from 'axios'

interface TypeResJob {
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}


export const GET_LIST_ALL_FIELD_OF_ACTIVITY_URL = `/api/field-of-activity/all`

export function getListAllFieldOfActivity() {
  return axios.get(GET_LIST_ALL_FIELD_OF_ACTIVITY_URL)
}



