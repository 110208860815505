import axios from 'axios'

interface TypeResArtist {
  count: number
  rows: Array<{
    address: string
    name: string
    phone: string
  }>
}

interface TypeParamsGetArtist {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_ARTIST_URL = `/api/artist`
export const APPROVE_OR_REJECT_ARTIST = `/api/artist`


export function getListArtists(params: TypeParamsGetArtist) {
  return axios.get<TypeResArtist>(GET_LIST_ARTIST_URL, { params })
}

export function approveOrRejectArtist(status: number, id: number | undefined) {
  return axios.put(`${APPROVE_OR_REJECT_ARTIST}/${id}`, {
    status
  })
}
