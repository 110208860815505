import axios from 'axios'

interface TypeResJob {
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetJob {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_JOB_URL = `/api/job`
export const CREATE_JOB_URL = `/api/job`
export const UPDATE_JOB_URL = `/api/job`
export const DELETE_JOB_URL = `/api/job`
export const GET_LIST_ALL_JOB_URL = `/api/job/all`


export function getListJobs(params: TypeParamsGetJob) {
  return axios.get<TypeResJob>(GET_LIST_JOB_URL, { params })
}

export function getListAllJobs() {
  return axios.get(GET_LIST_ALL_JOB_URL)
}

export function createJob(payload: object) {
  return axios.post(CREATE_JOB_URL, payload)
}

export function updateJob(payload: object, id: number | undefined) {
  return axios.post(`${UPDATE_JOB_URL}/${id}`, payload)
}

export function deleteJob(id: number | undefined) {
  return axios.delete(`${DELETE_JOB_URL}/${id}`)
}

