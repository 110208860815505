import axios from 'axios'

interface TypeResTopic {
  count: number
  rows: Array<{
    name: string
  }>
}

interface TypeParamsGetTopic {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_TOPIC_URL = `/api/topic`
export const CREATE_TOPIC_URL = `/api/topic`
export const UPDATE_TOPIC_URL = `/api/topic`
export const DELETE_TOPIC_URL = `/api/topic`
export const GET_LIST_ALL_TOPIC_URL = `/api/topic/all`

export function getListTopics(params: TypeParamsGetTopic) {
  return axios.get<TypeResTopic>(GET_LIST_TOPIC_URL, { params })
}

export function getListAllTopics(params: TypeParamsGetTopic) {
  return axios.get(GET_LIST_ALL_TOPIC_URL, { params })
}

export function createTopic(payload: any) {
  return axios.post(CREATE_TOPIC_URL, payload)
}

export function updateTopic(payload: any, id: number | undefined) {
  return axios.put(`${UPDATE_TOPIC_URL}/${id}`, payload)
}

export function deleteTopic(id: number | undefined) {
  return axios.delete(`${DELETE_TOPIC_URL}/${id}`)
}

