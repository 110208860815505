import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../_metronic/helpers';
import errorHelper from '../../utils/error-helper';
import successHelper from '../../utils/success-helper';
import { approveOrRejectArtist } from "../../apis/artistApis";
import { ARTIST_STATUS, CONTENT_TYPE, STATUS } from "../../constants";
import { approveOrRejectReport } from '../../apis/reportApis';

type Props = {
  onAfterHandle: () => void
}

const ModalDetailContent = forwardRef(({ onAfterHandle }: Props, ref) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => ({
    open(dataContent: any) {
      setVisible(true)
      setData(dataContent)
    }
  }))


  const onClose = () => {
    setVisible(false)
    setData(null)
  }

  const renderContent = () => {
    switch (data?.contentType) {
      case CONTENT_TYPE.POST:
        return (
          <>
            <div className='content-detail-text'>
              {data?.artistPost?.content}
            </div>
            {
              data?.artistPost?.artistPostImage?.map((link: any, index: number) => (
                <img
                  src={link.image}
                  className="w-100 content-detail-img"
                  key={index}
                />
              ))
            }
          </>
        )
      case CONTENT_TYPE.COMMENT:
        return (
          <>
            <div className='content-detail-text'>
              {data?.artistPostComment?.message}
            </div>
            {
              data?.artistPostComment?.artistPostCommentImage?.map((link: any, index: number) => (
                <img
                  src={link.image}
                  className="w-100 content-detail-img"
                  key={index}
                />
              ))
            }
          </>
        )
      case CONTENT_TYPE.COMMENT_ARTICLE:
        return (
          <>
            <div className='content-detail-text'>
              {data?.articleComment?.message}
            </div>
            {
              data?.articleComment?.articleComment?.map((link: any, index: number) => (
                <img
                  src={link.image}
                  className="w-100 content-detail-img"
                  key={index}
                />
              ))
            }
          </>
        )
    }
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={visible}
      dialogClassName='mw-700px h-auto'
      aria-hidden='true'
      backdrop="static"
      keyboard={false}
    >
      <form
        className='modal-content content-detail container'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            Detail Content
          </h5>
          <button
            type='reset'
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={onClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </button>
        </div>
        <div className='modal-body'>
          {renderContent()}
        </div>
        <div className='modal-footer d-flex justify-content-center'>
          <button type="reset" className='btn btn-light-primary' onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  )
})

export { ModalDetailContent };

