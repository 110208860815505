import axios from 'axios'

interface TypeResUser {
  count: number
  rows: Array<{
    address: string
    name: string
    phone: string
  }>
}

interface TypeParamsGetUser {
  search?: string
}

// const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST_USER_URL = `/api/user`
export const CREATE_USER_URL = `/api/user`
export const UPDATE_USER_URL = `/api/user`
export const DELETE_USER_URL = `/api/user`


export function getListUsers(params: TypeParamsGetUser) {
  return axios.get<TypeResUser>(GET_LIST_USER_URL, { params })
}

export function createUser(payload: object) {
  return axios.post(CREATE_USER_URL, payload)
}

export function updateUser(name: string, phone: string, address: string, description: string, id: number | undefined) {
  return axios.post(`${UPDATE_USER_URL}/${id}`, {
    name, phone, address, description
  })
}

export function deleteUser(id: number | undefined) {
  return axios.delete(`${DELETE_USER_URL}/${id}`)
}

export function changeStatusUser(id: number | undefined) {
  return axios.get(`/api/user/${id}/status`)
}

